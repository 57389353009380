import dynamic from 'next/dynamic';
import NotLoggedIn from './NotLoggedIn';
import TypingText from './typeaheadBot';
import ReactCompareImage from 'react-compare-image';
import HomepageImageSelector2 from './HomepageImageSelector2';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  GlobeAmericasIcon,
  PhotoIcon,
  PaintBrushIcon,
  RocketLaunchIcon,
  ArrowUpRightIcon
} from '@heroicons/react/20/solid';
const DynamicPricing = dynamic(() => import('../components/Pricing'), {
  loading: () => <p>Loading...</p>
});

// const useCaseData =

// import Pricing from './Pricing';
import {
  BuildingStorefrontIcon,
  HomeIcon,
  LightBulbIcon,
  WrenchScrewdriverIcon,
  CheckCircleIcon
  // StarIcon
} from '@heroicons/react/24/outline';

import { StarIcon, BoltIcon, HomeModernIcon } from '@heroicons/react/20/solid';
import FaqHome from './FaqHome';
import BrandLogos from './BrandLogos';
import React from 'react';
import { ImageGallery } from './ImageGallery2';
import { transferableAbortController } from 'util';

const isArray = (a) => {
  return !!a && a.constructor === Array;
};
const isObject = (a) => {
  return !!a && a.constructor === Object;
};
const steps = [
  {
    icon: <PhotoIcon className="w-6 h-6 text-white" />,
    title: 'Upload Your Property Images',
    description:
      'Start by uploading your property images. Our AI tool is set to turn any space into an appealing environment.'
  },
  {
    icon: <PaintBrushIcon className="w-6 h-6 text-white" />,
    title: 'Select Your Ideal Interior Design Style',
    description:
      'Choose from 20 different interior design styles. Our AI tool caters to your taste, be it for virtual staging or interior design.'
  },
  {
    icon: <RocketLaunchIcon className="w-6 h-6 text-white" />,
    title: 'Elevate Your Space with AI',
    description:
      'Use the best images to redecorate your home, or stage your property to perfection. Our AI tool will transform your space in seconds.'
  }
];

const HomePage = ({
  firstSignup,
  signInMessage,
  emailUploading,
  submitEmailForSignIn,
  selectFile,
  imageUrl,
  checkIfLoggedIn,
  data,
  translator
}) => {
  const cloudflareLoader = ({ src, width, quality }) => {
    const params = [`width=${parseInt(width)}`];
    if (quality) {
      params.push(`quality=${parseInt(quality)}`);
    }
    const paramsString = params.join(',');

    var new_url = src.replace(/\/[^\/]*$/, `/${paramsString}`);

    return new_url;
  };
  const router = useRouter();

  // const { t } = useTranslation('common');

  const spaces = translator('spaces', { returnObjects: true });

  const useCaseData = translator('use_cases', { returnObjects: true });

  const activeUseCaseData = isObject(useCaseData) ? useCaseData : { how: [] };
  const activeSpaces = isArray(spaces) ? spaces : [];

  // export default ImageGallery;

  return (
    <div>
      <div className="special-gradient-bg relative min-h-[675px]">
        <Image
          alt="purple detail"
          className="absolute top-20 left-0 "
          src="/vectorbg.svg"
          width={'789'}
          height={'483'}
        />

        <div className=" px-6 xl:px-0 md:py-10 max-w-7xl m-auto relative">
          <div className="text-left grid grid-col-1 justify-center w-full lg:grid lg:grid-cols-2 lg:items-center gap-16 justify-start">
            <div>
              <h1 className="flex flex-col font-bold   leading-[2.7rem] text-black  mb-0  sm:text-[2.7em] sm:leading-[3.3rem] m-0 gap-6 pt-6 md:pt-0">
                <span className="sm:mr-2 text-[50px] md:text-[60px]">
                  {translator('h1')}
                </span>
                {/* <br className="sm:hidden"></br> */}
                <TypingText words={activeSpaces} translator={translator} />
                {/* <br></br> */}

                <span className="text-[50px] md:text-[60px]">
                  {translator('h12')}
                </span>
              </h1>
              <p className=" font-medium text-[18px] text-[#9192A3] sm:text-xl my-4">
                {translator('h13')}
              </p>

              {/* <div className="flex flex-col gap-2 mt-4 text-gray-800">
                <p className="text-sm ">
                  <BoltIcon className="h-4 inline text-[#4138C2] mr-2" />
                  {translator('h14')}
                </p>
                <p className="text-sm">
                  <StarIcon className="h-4 inline text-[#4138C2] mr-2" />
                  {translator('h15')}
                </p>
                <p className="text-sm">
                  <HomeModernIcon className="h-4 inline text-[#4138C2] mr-2" />
                  {translator('h16')}
                </p>
              </div> */}
              <div className="justify-start flex flex-col gap-4">
                <div className="justify-start items-center gap-4 flex">
                  <CheckCircleIcon className="w-6 h-6 justify-center items-center flex shrink-0" />

                  <div className="text-left">
                    <span className="text-zinc-500 text-base font-bold ">
                      {translator('h14')}
                    </span>
                  </div>
                </div>
                <div className="justify-start items-center gap-4 flex">
                  <CheckCircleIcon className="w-6 h-6 justify-center items-center flex shrink-0" />
                  <div className="text-left">
                    <span className="text-zinc-500 text-base font-bold ">
                      {translator('h15')} ⭐⭐⭐⭐⭐
                    </span>
                  </div>
                </div>
                <div className="justify-start items-center gap-4 flex">
                  <CheckCircleIcon className="w-6 h-6 justify-center items-center flex shrink-0" />
                  <div className="text-left">
                    <span className="text-zinc-500 text-base font-bold ">
                      {translator('h16')}
                    </span>
                    {/* <span className="text-zinc-500 text-base font-bold ">
                      Space with Ai
                    </span> */}
                  </div>
                </div>
                <div className="justify-start items-center gap-4 flex">
                  <CheckCircleIcon className="w-6 h-6 justify-center items-center flex shrink-0" />
                  <div className="text-left">
                    <span className="text-zinc-500 text-base font-bold ">
                      {translator('scta')}
                    </span>
                    {/* <span className="text-zinc-500 text-base font-bold ">
                      {' '}
                      to enhance your home
                    </span> */}
                  </div>
                </div>
                {/* <div className="justify-start items-center gap-4 flex">
                  <div className="w-6 h-6 justify-center items-center flex">
                    
                  </div>
                  <div className="text-left">
                    <span className="text-zinc-500 text-base font-bold  underline">
                      Mix and match
                    </span>
                    <span className="text-zinc-500 text-base font-bold ">
                      {' '}
                      styles through images
                    </span>
                  </div>
                </div> */}
              </div>
              <div className="justify-start items-center inline-flex gap-0 mt-5">
                <img
                  className="w-[50px] h-[50px] rounded-[50px] border-2 border-neutral-100"
                  src="https://i.pravatar.cc/100?img=45"
                  alt="AI Interior Designer"
                />
                <img
                  className="w-[50px] h-[50px] rounded-[50px] -ml-4 border-2 border-neutral-100"
                  src="https://i.pravatar.cc/100?img=10"
                  alt="AI Interior Designer"
                />
                <img
                  className="w-[50px] h-[50px] rounded-[50px] -ml-4 border-2 border-neutral-100"
                  src="https://i.pravatar.cc/100?img=24"
                  alt="AI Interior Designer"
                />
                <img
                  className="w-[50px] h-[50px] rounded-[50px] -ml-4 border-2 border-neutral-100"
                  src="https://i.pravatar.cc/100?img=54"
                  alt="AI Interior Designer"
                />
                <div className="ml-2 text-center text-zinc-500 text-sm font-normal ">
                  {translator('overusers')}
                </div>
              </div>
              {/* <p className="font-bold text-[18px] text-[#4138C2] sm:text-xl mt-4">
                {translator('scta')}
              </p>
              <p className="text-[10px] italic  ">{translator('ccwarn')}</p> */}

              <div className="w-full md:w-auto">
                <NotLoggedIn
                  firstSignup={firstSignup}
                  signInMessage={signInMessage}
                  emailUploading={emailUploading}
                  submitEmailForSignIn={submitEmailForSignIn}
                  selectFile={selectFile}
                  imageUrl={imageUrl}
                  checkIfLoggedIn={checkIfLoggedIn}
                  translator={translator}
                />
              </div>
            </div>
            <div className="block">
              <div className="flex ">
                <ImageGallery key="xx" translator={translator} />
              </div>
            </div>
          </div>
          {/* <div className="w-full md:w-auto">
            <NotLoggedIn
              firstSignup={firstSignup}
              signInMessage={signInMessage}
              emailUploading={emailUploading}
              submitEmailForSignIn={submitEmailForSignIn}
              selectFile={selectFile}
              imageUrl={imageUrl}
              checkIfLoggedIn={checkIfLoggedIn}
              translator={translator}
            />
          </div> */}
        </div>
      </div>

      <div className="flex-col justify-center items-center gap-10 flex py-10 px-0.5 ">
        <div className="flex-col justify-start items-center gap-2 flex ">
          <div className="text-center text-zinc-800 text-[34px] font-bold font-['Hanno Test'] capitalize">
            <span className="gradient-text hanno-font">
              {translator('brand_first')}
            </span>{' '}
            <span className="hanno-font">{translator('brand_second')}</span>
            <span className="gradient-text hanno-font">
              {' '}
              {translator('brand_third')}
            </span>
          </div>
          <div className="text-center text-zinc-500 text-xs font-normal ">
            {translator('brand_desc')}
          </div>
        </div>
        <div className="hidden justify-center items-center gap-4 md:grid   grid-cols-4 max-w-[100rem]   px-2 md:px-6">
          <div className="grid grid-rows-2 gap-2 ">
            <div className=" relative bg-neutral-100 rounded-[20px] md:flex md:w-full md:h-full items-center justify-center">
              <Image className="" src="/redfin.png" width={234} height={80} />
            </div>
            <div className=" relative bg-neutral-100 rounded-[20px] md:flex md:w-full md:h-full items-center justify-center">
              <Image
                className=""
                src="/realtor.com.png"
                width={234}
                height={80}
              />
            </div>
          </div>

          <ReviewBento translator={translator} />
          <StatsBento translator={translator} />

          <div className="flex flex-col flex-1 grow gap-2">
            <div className=" relative bg-neutral-100 rounded-[20px] md:flex md:w-full md:h-full items-center justify-center grow">
              <Image
                className="rounded-[20px]"
                src="/zillowfinal.png"
                width={234}
                height={80}
              />
            </div>
            <div className="  relative bg-neutral-100 rounded-[20px] md:flex md:w-full md:h-full items-center justify-center grow">
              <Image className="" width={234} height={80} src="/century.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="p-2 flex flex-col gap-2 md:hidden ">
        <ReviewBento translator={translator} />
        <StatsBento translator={translator} />
        <div className="grid grid-cols-2 grid-rows-2 gap-4 items-center justify-center ">
          <div className=" relative bg-neutral-100 rounded-[20px] flex w-full h-full items-center justify-center">
            <Image className="" src="/redfin.png" width={234} height={80} />
          </div>
          <div className=" relative bg-neutral-100 rounded-[20px] flex w-full h-full items-center justify-center">
            <Image
              className=""
              src="/realtor.com.png"
              width={234}
              height={80}
            />
          </div>
          <div className="  relative bg-neutral-100 rounded-[20px] flex w-full h-full items-center justify-center grow">
            <Image className="" width={234} height={80} src="/century.png" />
          </div>

          <div className="relative bg-neutral-100 rounded-[20px] flex w-full h-full items-center justify-center grow">
            <Image
              className=""
              src="/zillowfinal.png"
              width={234}
              height={80}
            />
        </div>
        </div>
      </div>

      {/* <BrandLogos>
        {translator('brand_first')} <b className="text-[#4138C2]">253,458</b>{' '}
        {translator('brand_second')} <b className="text-[#6941C6]">1,376,850</b>{' '}
        renders
      </BrandLogos> */}

      <div className=" sm:flex items-center  bg-neutral-100 flex-col py-16 justify-center gap-10 relative px-10">
        <Image
          alt="purple detail"
          className="absolute top-20 left-0 "
          src="/vectorbg.svg"
          width={'789'}
          height={'483'}
        />

        <div className=" justify-between items-center flex flex-col lg:flex-row gap-16 md:gap-44 ">
          <div className="flex-col justify-center items-start gap-10 inline-flex">
            <div className="hanno-font">
              <span className="gradient-text text-[38px] font-normal font-['Hanno Test'] capitalize">
                {translator('how1')}
              </span>
              <span className="text-zinc-800 text-[38px] font-normal font-['Hanno Test'] capitalize">
                {' '}
                {translator('how2')}{' '}
              </span>
              <span className="gradient-text text-[38px] font-normal font-['Hanno Test'] capitalize">
                {translator('how3')}
              </span>
              <span className="text-zinc-800 text-[38px] font-normal font-['Hanno Test'] capitalize">
                {' '}
                {translator('how4')}
              </span>
            </div>

            <div className="flex-col justify-center items-start gap-6 flex max-w-[400px]">
              {activeUseCaseData?.how?.map((step, index) => (
                <div
                  className="justify-center items-center gap-8 flex flex-row"
                  key={index}
                >
                  <div className="w-10 h-10 justify-center items-center gap-2.5 flex bg-zinc-800 rounded-full p-2">
                    {steps[index].icon}
                  </div>
                  <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="text-zinc-800 text-lg font-extrabold capitalize">
                      {step.title}
                    </div>
                    <div className="text-zinc-500 text-base font-normal">
                      {step.lead}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <button
              onClick={() => {
                console.log('121');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              className="z-10 text-white  font-extrabold h-12 px-4 py-2 bg-zinc-800 rounded-[200px] border  justify-center items-center gap-2 inline-flex"
            >
              {translator('main_cta')}
            </button>
          </div>
          <div className="relative mt-16 flex items-center justify-center md:ml-10 ml-44">
            <Image
              className="rounded-2xl shadow h-[150px] w-[150px] md:h-[350px] md:w-[350px] object-cover max-w-none"
              src="/beforekitchen.png"
              width={323}
              height={394}
            />
            <div className="absolute w-full bottom-6 right-44">
              <div className="relative">
                <Image
                  className=" rounded-[20px] shadow-2xl h-[200px] w-[200px] md:h-[400px] md:w-[400px] object-cover max-w-none "
                  src="/afterkitchen.png"
                  height={482}
                  width={465}
                />
                <div className="w-[88px] h-[45px] left-0 bottom-0 absolute bg-neutral-100 rounded-bl-lg rounded-tr-lg shadow flex items-center justify-center">
                  <div className="gradient-text text-zinc-800 text-base font-normal font-['Bebas Neue'] uppercase">
                    after
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="  flex justify-center items-center   py-16">
        <div className="justify-center items-center md:items-start flex flex-col lg:flex-row gap-10 ">
          <HomepageImageSelector2 translator={translator} />
        </div>
      </div>
      <div className="h-12 px-4 mt-10  justify-center items-center gap-2 flex flex-col mb-10 ">
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          className="text-center text-white text-base font-bold   border bg-black  p-4 rounded-[200px]"
        >
          {translator('main_cta')}
        </button>
      </div>

      {/* <div className="bg-white relative p-5 max-w-7xl mx-auto">
        <div className="text-center font-bold text-transparent text-lg bg-clip-text bg-gradient-to-r from-[#4138C2] to-[#EB813F] sm:text-2xl mb-4">
          {translator('transform')}
        </div>
        <div className="text-center font-medium text-lg text-black mb-4 sm:mt-1 sm:mb-6">
          {translator('transform2')}
        </div>
        <HomepageImageSelector></HomepageImageSelector>
        <div className="flex">
          <button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="bg-black text-white mt-10 rounded-lg p-4 max-w-md font-bold  mx-auto"
          >
            {translator('get_started')}
          </button>
        </div>
      </div> */}
      <div className="flex items-center flex-col py-16 justify-center gap-10 relative bg-neutral-100 ">
        <h2 className="text-3xl md:text-4xl hanno-font text-center">
          <span className="gradient-text ">{translator('tform')} </span>
          {translator('tform2')}{' '}
          <span className="gradient-text">{translator('tform3')}</span>
        </h2>
        {/* <div className="flex flex-col-reverse md:flex-row gap-10">
          <div className="flex flex-col gap-8 items-start flex-1">
            <h2 className="font-bold text-2xl md:text-4xl">
              {useCaseData.featureOne.title}
            </h2>
            <div className="text-base text-gray-600">
              {useCaseData.featureOne.lead}
            </div>
            <button>
              <a
                href="/"
                className="bg-black text-white mt-10 rounded-lg p-2.5 max-w-md font-bold  self-start"
              >
                Try it now
              </a>
            </button>
          </div>
          <div className="grid grid-row-1 items-center flex-1">
            <img className="" src={useCaseData.featureOne.image} />
          </div>
        </div> */}
        <div className="flex flex-col   gap-10 md:flex-row-reverse  ">
          <Image
            alt="purple detail"
            className="absolute top-20 left-0 "
            src="/vectorbg.svg"
            width={'789'}
            height={'483'}
          />
          <div className="grid grid-row-1 items-center flex-1">
            {/* <img
              src={activeUseCaseData.featureTwo?.image}
              alt="Tailored AI Interior Design for your preferences"
            /> */}
            <Image
              src="/savetime.png"
              width={695}
              height={400}
              priority={false}
              // loader={cloudflareLoader}
              // className="mb-3 flex-1 max-w-xs max-h-40 object-contain"
              alt="Save Time and Money using AI Interior Design "
            />
          </div>
          <div className="flex flex-col gap-6 items-start flex-1 p-10 max-w-xl">
            <h2 className="font-bold text-2xl md:text-4xl">
              {activeUseCaseData.featureTwo?.title}
            </h2>
            <div className="text-base text-gray-600">
              {activeUseCaseData.featureTwo?.lead}
            </div>
            <button
              className="bg-black text-white mt-10 rounded-lg p-2.5 max-w-md font-bold  self-start"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {translator('main_cta')}
            </button>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row-reverse gap-10">
          <div className="flex flex-col gap-8 items-start flex-1 p-10 max-w-xl">
            <h2 className="font-bold text-2xl md:text-4xl">
              {activeUseCaseData.featureThree?.title}
            </h2>
            <div className="text-base text-gray-600">
              {activeUseCaseData.featureThree?.lead}
            </div>
            <button
              className="bg-black text-white mt-10 rounded-lg p-2.5 max-w-md font-bold  "
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {translator('alternative_cta')}
            </button>
          </div>
          <div className="grid grid-row-1 items-center flex-1">
            {/* <img
              src={activeUseCaseData.featureThree?.image}
              alt="Beautiful Furniture Staging using Ai Interior Design "
            /> */}
            <Image
              src="/tailoranyspace.png"
              width={695}
              height={400}
              priority={false}
              // loader={cloudflareLoader}
              // className="mb-3 flex-1 max-w-xs max-h-40 object-contain"
              alt="Beautiful Furniture Staging using Ai Interior Design "
            />
          </div>
        </div>
        <div className="flex  flex-col md:flex-row-reverse gap-10">
          <div className="grid grid-row-1 items-center">
            {/* <img src={activeUseCaseData.featureFour?.image} /> */}
            <Image
              src={'/adjusteven.png'}
              width={695}
              height={400}
              priority={false}
              // loader={cloudflareLoader}
              // className="mb-3 flex-1 max-w-xs max-h-40 object-contain"
              alt="Adjust Even The Smallest Details"
            />
          </div>
          <div className="flex flex-col gap-6 items-start  p-10 max-w-xl">
            <h2 className="font-bold text-2xl md:text-4xl">
              {activeUseCaseData.featureFour?.title}
            </h2>
            <div className="text-base text-gray-600">
              {activeUseCaseData.featureFour?.lead}
            </div>
            <button
              className="bg-black text-white mt-10 rounded-lg p-2.5 max-w-md font-bold  self-start"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {translator('alternative_cta')}
            </button>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row-reverse gap-10">
          <div className="flex flex-col gap-8 items-start flex-1 p-10 max-w-xl">
            <h2 className="font-bold text-2xl md:text-4xl ">
              {activeUseCaseData.featureFive?.title}
            </h2>
            <div className="text-base text-gray-600">
              {activeUseCaseData.featureFive?.lead}
            </div>
            <button
              className="bg-black text-white mt-10 rounded-lg p-2.5 max-w-md font-bold  "
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {translator('alternative_cta')}
            </button>
          </div>
          <div className="grid grid-row-1 items-center flex-1">
            {/* <img
              src={activeUseCaseData.featureThree?.image}
              alt="Beautiful Furniture Staging using Ai Interior Design "
            /> */}
            <Image
              src={'/roomadvice.png'}
              width={695}
              height={400}
              priority={false}
              // loader={cloudflareLoader}
              // className="mb-3 flex-1 max-w-xs max-h-40 object-contain"
              alt="Beautiful Furniture Staging using Ai Interior Design "
            />
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-10">
          <div className="flex flex-col gap-8 items-start flex-1 p-10 max-w-xl">
            <h2 className="font-bold text-2xl md:text-4xl ">
              {activeUseCaseData.featureFive?.title}
            </h2>
            <div className="text-base text-gray-600">
              {activeUseCaseData.featureFive?.lead}
            </div>
            <button
              className="bg-black text-white mt-10 rounded-lg p-2.5 max-w-md font-bold  "
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {translator('alternative_cta')}
            </button>
          </div>
          <div className="grid grid-row-1 items-center flex-1">
            {/* <img
              src={activeUseCaseData.featureThree?.image}
              alt="Beautiful Furniture Staging using Ai Interior Design "
            /> */}
            <Image
              src={'/stylemix.png'}
              width={695}
              height={400}
              priority={false}
              // loader={cloudflareLoader}
              // className="mb-3 flex-1 max-w-xs max-h-40 object-contain"
              alt="Beautiful Furniture Staging using Ai Interior Design "
            />
          </div>
        </div>
      </div>

      <div className="px-6 flex-col justify-start items-start gap-6 flex my-16">
        <div className="flex bg-white flex-col justify-start items-center gap-10  ">
          <div className="flex-col justify-center items-start flex md:pl-28 hanno-font">
            {' '}
            <span className="text-zinc-800 text-[34px]  md:text-[80px] font-normal font-['Hanno Test'] leading-[88px]">
              {translator('tools_1')}
            </span>
            <span className="text-blue-600 text-[34px]  md:text-[80px] font-normal font-['Hanno Test']  gradient-text">
              {translator('tools_2')}
            </span>
            {/* <span className="text-blue-600 text-[34px]  md:text-[80px] font-normal font-['Hanno Test'] leading-[88px]">
              {' '}
            </span> */}
          </div>
          <div
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="h-12 px-4 py-2 rounded-[200px] border border-blue-600 justify-center items-center gap-2 inline-flex ml-28 self-start"
          >
            <div className="text-center text-zinc-800 text-base font-bold ">
              Upload Image
            </div>
            <div className="w-6 h-6 relative" />
          </div>
        </div>
        <div className="-ml-6 md:ml-0">
          {/* <div className="h-[373px] justify-center items-center gap-6 inline-flex">
            <div className="w-[838px] h-[373px] relative">
              <Image
                className="w-[607.68px] h-[373px] left-0 top-0 absolute rounded-[20px]"
                src="/chooseyourstyle.jpg"
                width={608}
                height={373}
              />
              <div className="w-[838px] h-[76px] px-[35px] py-[18px] left-0 top-[297px] absolute bg-zinc-800 to-zinc-800 rounded-bl-[20px] rounded-br-[20px] justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Living Room Renovation
                </div>
                <div className="w-6 h-6 justify-center items-center gap-1.5 flex">
                  <div className="w-6 h-6 bg-white rounded-[21px]" />
                  <div className="w-[14.40px] h-[14.40px] relative" />
                </div>
              </div>
            </div>
            <div className="w-72 h-[373px] relative">
              <Image
                className="w-72 h-[373px] left-0 top-0 absolute rounded-[20px]"
                src="/workspacerenovation.png"
                width={72}
                height={373}
              />
              <div className="w-72 h-[76px] px-[35px] py-[18px] left-0 top-[297px] absolute bg-zinc-800 to-zinc-800 rounded-bl-[20px] rounded-br-[20px] justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Workspace Renovation
                </div>
                <div className="w-6 h-6 justify-center items-center gap-1.5 flex">
                  <div className="w-6 h-6 bg-white rounded-[21px]" />
                  <div className="w-[14.40px] h-[14.40px] relative" />
                </div>
              </div>
            </div>
            <div className="w-[386px] h-[373px] relative">
              <Image
                className="w-[385.69px] h-[373px] left-[0.31px] top-0 absolute rounded-[20px]"
                src="/facademakeover.jpg"
                width={386}
                height={373}
              />
              <div className="w-[386px] h-[76px] px-[35px] py-[18px] left-0 top-[297px] absolute bg-zinc-800 to-zinc-800 rounded-bl-[20px] rounded-br-[20px] justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Facade Makeover
                </div>
                <div className="w-6 h-6 justify-center items-center gap-1.5 flex">
                  <div className="w-6 h-6 bg-white rounded-[21px]" />
                  <div className="w-[14.40px] h-[14.40px] relative" />
                </div>
              </div>
            </div>
            <div className="w-72 h-[373px] relative">
              <Image
                className="w-[287.80px] h-[373px] left-[0.20px] top-0 absolute rounded-[20px]"
                width={288}
                height={373}
                src="/kitchenrenovation.jpg"
              />
              <div className="w-72 h-[76px] px-[35px] py-[18px] left-0 top-[297px] absolute bg-zinc-800 to-zinc-800 rounded-bl-[20px] rounded-br-[20px] justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-base font-bold  uppercase">
                  kitchen Renovation
                </div>
                <div className="w-6 h-6 justify-center items-center gap-1.5 flex">
                  <div className="w-6 h-6 bg-white rounded-[21px]" />
                  <div className="w-[14.40px] h-[14.40px] relative" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="grid lg:grid-rows-2 grid-flow-col-dense  gap-6 md:flex-wrap overflow-auto w-[95vw] no-scrollbar">
            <div className="relative min-w-[500px] min-h-[373px] ">
              <Image
                className="rounded-[20px] object-cover"
                src="/chooseyourstyle.jpg"
                fill={true}
                style={{ objectFit: 'cover' }}
                alt="Living Room Renovation"
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-transparent rounded-bl-[20px] rounded-br-[20px] flex justify-center items-center gap-2">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Workspace Renovation
                </div>
                <ArrowUpRightIcon className="h-6 w-6 tex-white fill-white" />
              </div>
            </div>
            <div className="relative min-w-[300px] min-h-[373px]  ">
              <Image
                className="rounded-[20px] object-cover"
                src="/afterkitchen.png"
                fill={true}
                style={{ objectFit: 'cover' }}
                alt="AI Kitchen Designer"
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-transparent rounded-bl-[20px] rounded-br-[20px] flex justify-center items-center gap-2">
                <div className="text-center text-white text-base font-bold  uppercase">
                  AI Kitchen Designer
                </div>
                <ArrowUpRightIcon className="h-6 w-6 tex-white fill-white" />
              </div>
            </div>
            <div className="relative min-w-[300px] min-h-[373px]  ">
              <Image
                className="rounded-[20px] object-cover"
                src="/afterkitchen.png"
                fill={true}
                style={{ objectFit: 'cover' }}
                alt="Kitchen Renovation"
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-transparent rounded-bl-[20px] rounded-br-[20px] flex justify-center items-center gap-2">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Kitchen Renovation
                </div>
                <ArrowUpRightIcon className="h-6 w-6 tex-white fill-white" />
              </div>
            </div>

            <div className="relative min-w-[500px] min-h-[373px]">
              <Image
                className="rounded-[20px] object-cover"
                src="/workspacerenovation.png"
                fill={true}
                style={{ objectFit: 'cover' }}
                alt="Workspace Renovation"
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-transparent rounded-bl-[20px] rounded-br-[20px] flex justify-center items-center gap-2">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Living Room Refresher
                </div>
                <ArrowUpRightIcon className="h-6 w-6 tex-white fill-white" />
              </div>
            </div>
            <div className="relative min-w-[500px] min-h-[373px] ">
              <Image
                className="rounded-[20px] object-cover"
                src="/facademakeover.jpg"
                fill={true}
                style={{ objectFit: 'cover' }}
                alt="Facade Renovation"
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-transparent rounded-bl-[20px] rounded-br-[20px] flex justify-center items-center gap-2">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Facade Renovation
                </div>
                <ArrowUpRightIcon className="h-6 w-6 tex-white fill-white" />
              </div>
            </div>
            <div className="relative min-w-[500px] min-h-[373px] grow">
              <Image
                className="rounded-[20px] object-cover"
                src="/kitchenrenovation.jpg"
                fill={true}
                style={{ objectFit: 'cover' }}
                alt="Kitchen Cabinet Replacment"
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-transparent rounded-bl-[20px] rounded-br-[20px] flex justify-center items-center gap-2">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Kitchen Cabinet Replacment
                </div>
                <ArrowUpRightIcon className="h-6 w-6 tex-white fill-white" />
              </div>
            </div>
            <div className="relative min-w-[500px] min-h-[373px] grow">
              <Image
                className="rounded-[20px] object-cover"
                src="/bedroomrenovation.jpg"
                alt="Bedroom Designer"
                fill={true}
                style={{ objectFit: 'cover' }}
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-transparent rounded-bl-[20px] rounded-br-[20px] flex justify-center items-center gap-2">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Bedroom Designer
                </div>
                <ArrowUpRightIcon className="h-6 w-6 tex-white fill-white" />
              </div>
            </div>
            <div className="relative min-w-[500px] min-h-[373px] grow">
              <Image
                className="rounded-[20px] object-cover"
                alt="Pool Cleanup"
                src="/pool.png"
                fill={true}
                style={{ objectFit: 'cover' }}
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-transparent rounded-bl-[20px] rounded-br-[20px] flex justify-center items-center gap-2">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Pool Cleanup
                </div>
                <ArrowUpRightIcon className="h-6 w-6 tex-white fill-white" />
              </div>
            </div>
          </div>

          <div className=" justify-center items-center gap-6 flex flex-row">
            {/* <div className=" relative ">
              <Image
                className="rounded-[20px]"
                src="/afterkitchen.png"
                height={373}
                width={608}
                // fill={true}
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-zinc-800 rounded-bl-[20px] rounded-br-[20px] justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-base font-bold  uppercase">
                  kitchen Renovation
                </div>
                <div className="w-6 h-6 justify-center items-center gap-1.5 flex">
                  <div className="w-6 h-6 bg-white rounded-[21px]" />
                  <div className="w-[14.40px] h-[14.40px] relative" />
                </div>
              </div>
            </div>
            <div className="relative">
              <Image
                className="rounded-[20px]"
                src="/workspacerenovation.png"
                width={300}
                height={373}
              />
              <div className="bottom-0 w-full px-[35px] py-[18px] absolute bg-zinc-800 to-zinc-800 rounded-bl-[20px] rounded-br-[20px] justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-base font-bold  uppercase">
                  kitchen Renovation
                </div>
                <div className="w-6 h-6 justify-center items-center gap-1.5 flex">
                  <div className="w-6 h-6 bg-white rounded-[21px]" />
                  <div className="w-[14.40px] h-[14.40px] relative" />
                </div>
              </div>
            </div> */}
            {/* <div className=" relative">
              <Image
                className=" rounded-[20px]"
                src="/chooseyourstyle.jpg"
                width={608}
                height={373}
              />
              <div className="w-[607.24px] h-[76px] px-[35px] py-[18px] left-[0.76px] top-[296.70px] absolute bg-zinc-800 to-zinc-800 rounded-bl-[20px] rounded-br-[20px] justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Living Room Renovation
                </div>
                <div className="w-6 h-6 justify-center items-center gap-1.5 flex">
                  <div className="w-6 h-6 bg-white rounded-[21px]" />
                  <div className="w-[14.40px] h-[14.40px] relative" />
                </div>
              </div>
            </div> */}
            {/* <div className="w-[608px] h-[373px] relative">
              <img
                className="w-[608px] h-[373px] left-0 top-0 absolute rounded-[20px]"
                src="/bedroomrenovation.jpg"
              />
              <div className="w-[607.56px] h-[76px] px-[35px] py-[18px] left-[0.32px] top-[296.70px] absolute bg-zinc-800 to-zinc-800 rounded-bl-[20px] rounded-br-[20px] justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-base font-bold  uppercase">
                  Livingroom Renovation
                </div>
                <div className="w-6 h-6 justify-center items-center gap-1.5 flex">
                  <div className="w-6 h-6 bg-white rounded-[21px]" />
                  <div className="w-[14.40px] h-[14.40px] relative" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="  bg-neutral-100 flex-col justify-center md:items-center gap-10 flex   px-6">
        <div className="flex-col justify-center items-center gap-10 flex my-16 ">
          <div className="hanno-font text-[38px]">
            <span className="text-zinc-800 font-normal  capitalize">
              {translator('who1')}{' '}
            </span>
            <span className="gradient-text  font-bold  capitalize">
              {translator('who2')}{' '}
            </span>
            <span className="text-zinc-800 font-normal  capitalize">
              {translator('who3')}{' '}
            </span>
            <span className="gradient-text  font-bold  capitalize">
              {translator('who4')}
            </span>
          </div>
          <div className=" justify-center items-center gap-[30px] flex md:flex-row flex-col ">
            <div className="flex flex-col  justify-start items-start gap-2  max-w-2xl">
              <div className="  p-10 bg-white rounded-[20px] shadow border border-neutral-100 flex-col justify-start items-start gap-6 flex">
                <div className="justify-start items-start gap-2.5 inline-flex">
                  <div className="text-zinc-800 hover:gradient-text text-[28px] font-extrabold  capitalize">
                    {translator('who_can_interior')}
                  </div>
                </div>
                <div className="  flex-col justify-start items-start gap-6 flex">
                  <div className=" text-zinc-500 text-base font-normal ">
                    {translator('who_can_interior_desc')}
                  </div>
                </div>
              </div>
              <div className="  p-10 bg-white rounded-[20px] shadow border border-blue-600 flex-col justify-start items-start gap-6 flex">
                <div className="justify-start items-start gap-2.5 inline-flex">
                  <div className="gradient-text text-[28px] font-extrabold  capitalize">
                    {translator('who_can_realestate')}
                  </div>
                </div>
                <div className="  flex-col justify-start items-start gap-6 flex">
                  <div className=" text-zinc-500 text-base font-normal ">
                    {translator('who_can_realestate_desc')}
                  </div>
                </div>
              </div>
              <div className=" p-10 bg-white rounded-[20px] shadow border border-neutral-100 flex-col justify-start items-start gap-6 flex">
                <div className="justify-start items-start gap-2.5 inline-flex">
                  <div className="text-zinc-800 hover:gradient-text text-[28px] font-extrabold  capitalize">
                    {translator('who_can_homeowners')}
                  </div>
                </div>
                <div className=" flex-col justify-start items-start gap-6 flex">
                  <div className="text-zinc-500 text-base font-normal ">
                    {translator('who_can_homeowners_desc')}
                  </div>
                </div>
              </div>
              <div className=" p-10 bg-white rounded-[20px] shadow border border-neutral-100 flex-col justify-start items-start gap-6 flex">
                <div className="justify-start items-start gap-2.5 inline-flex">
                  <div className="text-zinc-800 hover:gradient-text text-[28px] font-extrabold  capitalize">
                    {translator('who_can_furniture')}
                  </div>
                </div>
                <div className=" flex-col justify-start items-start gap-6 flex">
                  <div className="text-zinc-500 text-base font-normal ">
                    {translator('who_can_furniture_desc')}
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden xl:block w-[695px] h-[677px] relative flex-1">
              <Image
                className="w-[405px] h-[432px] left-[290px] top-[245px] absolute rounded-[20px] shadow"
                width={405}
                height={432}
                src="/beforekat.jpeg"
                alt="Before Kitchen Renovation"
              />
              <div className="w-[85px] h-[45px] left-[290px] top-[632px] absolute">
                <div className="w-[85px] h-[45px] left-0 top-0 absolute bg-neutral-100 rounded-bl-[20px] rounded-tr-lg" />
                <div className="left-[16px] top-[8px] absolute text-zinc-800 hover:gradient-text text-xl font-normal font-['Bebas Neue']">
                  {translator('before')}
                </div>
              </div>
              <div className="w-[464.79px] h-[483px] left-0 top-0 absolute">
                <Image
                  className="w-[464.79px] h-[482.54px] left-0 top-0 absolute rounded-[20px] shadow"
                  src="/afterkat.jpeg"
                  width={465}
                  height={482}
                  alt="After Kitchen Renovation"
                />
                <div className="w-[88px] h-[45px] left-0 top-[438px] absolute bg-neutral-100 rounded-bl-lg rounded-tr-lg shadow">
                  <div className="gradient-text left-[16px] top-[8px] absolute text-zinc-800 text-xl font-normal font-['Bebas Neue'] uppercase">
                    {translator('after')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="m-5 max-w-7xl sm:mx-auto sm:text-center pb-4">
        <div className="font-bold text-lg text-black mb-0 sm:text-xl">
          SofaBrain
        </div>
        <div className="font-bold text-lg text-black mb-0">
          {translator('who_can')}
        </div>
        <div className="grid sm:grid-cols-2 gap-x-4">
          <div className="bg-[#4138C21A] rounded-lg text-left p-5 mt-5">
            <div className="bg-black text-white p-3 rounded-lg inline-block">
              <LightBulbIcon className="h-[20px]"></LightBulbIcon>
            </div>
            <p className="text-[#4138C2] font-bold mt-1">
              {translator('who_can_interior')}
            </p>
            <p className="text-sm text-black mt-3">
              {translator('who_can_interior_desc')}
            </p>
            <div className="flex">
              <button
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className="bg-black text-white w-full mt-3 rounded-lg p-1.5 max-w-md font-bold mx-auto"
              >
                {translator('main_cta')}
              </button>
            </div>
          </div>
          <div className="bg-[#4138C21A] rounded-lg text-left p-5 mt-5">
            <div className="bg-black text-white p-3 rounded-lg inline-block">
              <HomeIcon className="h-[20px]"></HomeIcon>
            </div>
            <p className="text-[#4138C2] font-bold mt-1">
              {translator('who_can_realestate')}
            </p>
            <p className="text-sm text-black mt-3">
              {translator('who_can_realestate_desc')}
            </p>
            <div className="flex">
              <button
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className="bg-black text-white w-full mt-3 rounded-lg p-1.5 max-w-md font-bold mx-auto"
              >
                {translator('main_cta')}
              </button>
            </div>
          </div>
          <div className="bg-[#4138C21A] rounded-lg text-left p-5 mt-5">
            <div className="bg-black text-white p-3 rounded-lg inline-block">
              <WrenchScrewdriverIcon className="h-[20px]"></WrenchScrewdriverIcon>
            </div>
            <p className="text-[#4138C2] font-bold mt-1">
              {translator('who_can_homeowners')}
            </p>
            <p className="text-sm text-black mt-3">
              {translator('who_can_homeowners_desc')}
            </p>
            <div className="flex">
              <button
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className="bg-black text-white w-full mt-3 rounded-lg p-1.5 max-w-md font-bold mx-auto"
              >
                {translator('alternative_cta')}
              </button>
            </div>
          </div>
          <div className="bg-[#4138C21A] rounded-lg text-left p-5 mt-5 hidden sm:block">
            <div className="bg-black text-white p-3 rounded-lg inline-block">
              <BuildingStorefrontIcon className="h-[20px]"></BuildingStorefrontIcon>
            </div>
            <p className="text-[#4138C2] font-bold mt-1">
              {translator('who_can_furniture')}
            </p>
            <p className="text-sm text-black mt-3">
              {translator('who_can_furniture_desc')}
            </p>
            <div className="flex">
              <button
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className="bg-black text-white w-full mt-3 rounded-lg p-1.5 max-w-md font-bold mx-auto"
              >
                {translator('cta_furniture')}
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="m-5 pb-5 max-w-7xl sm:mx-auto my-16">
        <div className="font-bold text-lg text-black mb-4 sm:mb-2 sm:text-center sm:text-4xl ">
          {translator('pricing')}
        </div>
        <div className="font-medium text-md text-black mb-0 sm:text-center">
          {translator('pricing_desc')}
        </div>
        <DynamicPricing
          openModal={() => {}}
          hideTeams={true}
          translator={translator}
        />
        {/* <p className="m-3 text-lg">
          Need API Access?{' '}
          <a className="text-indigo-600" href="/contact">
            {' '}
            Contact us{' '}
          </a>{' '}
          for a custom quote{' '}
        </p> */}
      </div>
      <div className=" flex-col justify-center items-center gap-24 py-16 ">
        <div className="flex-col justify-center items-center gap-2 flex hanno-font text-4xl text-center md:text-[38px] ">
          <div>
            <span className="text-zinc-800  font-normal font-['Hanno Test'] capitalize">
              {translator('faq1')}
            </span>{' '}
            <span className="gradient-text  font-normal font-['Hanno Test'] capitalize">
              {translator('faq2')}
            </span>
            <span className="text-zinc-800  font-normal font-['Hanno Test'] capitalize">
              {' '}
              {translator('faq3')}{' '}
            </span>
            <span className="gradient-text  font-normal font-['Hanno Test'] capitalize">
              {translator('faq4')}
            </span>
          </div>
        </div>
        <div className="flex-col justify-start items-center gap-4 flex pt-16 px-4">
          <div className="justify-start items-start gap-4 flex flex-col md:grid md:grid-cols-2">
            <div className="p-4 bg-white rounded-2xl shadow border border-neutral-100 flex-col justify-start items-start gap-3 flex">
              <div className="text-zinc-500 text-base font-normal font-['Plus Jakarta Sans'] leading-tight">
                ⭐⭐⭐⭐⭐
              </div>
              <div className=" justify-between items-center flex">
                <div className="justify-start items-center flex">
                  <div className="text-zinc-800 text-base font-bold ">
                    {translator('review1_title')}
                  </div>
                </div>
              </div>
              <div className=" text-zinc-500 text-base font-normal ">
                {translator('review1_desc')}
              </div>
              <div className="justify-between items-center ">
                <div className="text-zinc-800 text-base font-bold ">
                  Zach V.
                </div>
                <div className="text-zinc-500 text-sm font-normal ">
                  {translator('review1_job')}
                </div>
              </div>
            </div>
            <div className="p-4 bg-white rounded-2xl shadow border border-neutral-100 flex-col justify-start items-start gap-3 flex">
              <div className="text-zinc-500 text-base font-normal  leading-tight">
                ⭐⭐⭐⭐⭐
              </div>
              <div className=" justify-between items-center flex">
                <div className="justify-start items-center flex">
                  <div className="text-zinc-800 text-base font-bold ">
                    {translator('review2_title')}
                  </div>
                </div>
              </div>
              <div className=" text-zinc-500 text-base font-normal ">
                {translator('review2_desc')}
              </div>
              <div className="justify-between items-center ">
                <div className="text-zinc-800 text-base font-bold ">
                  Hunter C.
                </div>
                <div className="text-zinc-500 text-sm font-normal ">
                  {translator('review2_job')}
                </div>
              </div>
            </div>
            <div className="p-4 bg-white rounded-2xl shadow border border-neutral-100 flex-col justify-start items-start gap-3 ">
              <div className="justify-between items-center ">
                <div className="text-zinc-500 text-base font-normal  leading-tight">
                  ⭐⭐⭐⭐⭐
                </div>
                <div className="justify-start items-center flex">
                  <div className="text-zinc-800 text-base font-bold ">
                    {translator('review3_title')}
                  </div>
                </div>
              </div>
              <div className=" text-zinc-500 text-base font-normal ">
                {translator('review3_desc')}
                <br />
              </div>
              <div className="justify-between items-center ">
                <div className="text-zinc-800 text-base font-bold ">
                  Kali D.
                </div>
                <div className="text-zinc-500 text-sm font-normal ">
                  {translator('review3_job')}
                </div>
              </div>
            </div>
            <div className="p-4 bg-white rounded-2xl shadow border border-neutral-100 flex-col justify-start items-start gap-3 ">
              <div className="text-zinc-500 text-base font-normal  leading-tight">
                ⭐⭐⭐⭐⭐
              </div>
              <div className="justify-between items-center ">
                <div className="justify-start items-center flex">
                  <div className="text-zinc-800 text-base font-bold ">
                    {translator('review4_title')}
                  </div>
                </div>
              </div>
              <div className=" text-zinc-500 text-base font-normal ">
                {translator('review4_desc')}
                <br />
              </div>
              <div className="justify-between items-center ">
                <div className="text-zinc-800 text-base font-bold ">
                  Jessica L.
                </div>
                <div className="text-zinc-500 text-sm font-normal ">
                  {translator('review4_job')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="p-5 max-w-7xl mx-auto">
        <div className="text-center font-medium text-lg text-black mb-4 sm:text-xl">
          Explore recent designs by{' '}
          <span className="font-bold text-[#4138C2]">SofaBrain</span>
        </div>
        {data.slice(0, 3).map((item, i) => (
          <DynamicRenderItem render={item} key={`${item.id}-${i}`} />
        ))}
        <div className="flex">
          <button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="bg-black text-white mt-10 rounded-lg p-2.5 max-w-md font-bold  self-start"
          >
            Try it for Free
          </button>
        </div>
      </div>
      <hr className="bg-[#E9E9E9 border mb-2 max-w-7xl m-16 sm:mx-auto sm:mb-4"></hr> */}
      <div className="m-5 pb-5 max-w-7xl sm:mx-auto">
        <div className="font-bold text-2xl text-black mb-4 sm:mb-2 sm:text-center sm:text-4xl hanno-font">
          {/* {translator('faq')} */}
          <span className="">{translator('faq5')}</span>{' '}
          <span className="gradient-text">{translator('faq6')}</span>
        </div>
        <div className="font-medium text-md text-black mb-0 sm:text-center">
          {translator('faq_desc')}{' '}
          <span className="font-bold text-gradient hanno-font">sofabrain</span>
        </div>
        <FaqHome></FaqHome>
      </div>
      <div className="flex p-5 pt-0">
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          className="bg-black text-white mt-10 rounded-lg p-2.5 max-w-md font-bold  mx-auto"
        >
          {translator('main_cta')}
        </button>
      </div>
    </div>
  );
};

export default HomePage;

function StatsBento({ translator }) {
  return (
    <div className="  flex-1 flex p-6 bg-neutral-100 rounded-[20px] flex-col justify-start items-center gap-6 h-full">
      <div className="justify-start items-center gap-2 flex flex-col">
        <div className="grow shrink basis-0 text-zinc-500 text-base md:text-base font-normal text-center">
          {/* {translator('brand_desc')} */}
          {translator('h16')}
        </div>
        <div className="mt-2 xs:mt-10">
          <Image className="" src="/bentogrid.png" width={300} height={232} />
        </div>
      </div>
    </div>
  );
}

function ReviewBento({ translator }) {
  return (
    <div className="flex px-6 py-[15px] bg-neutral-100 rounded-[20px] flex-col justify-center items-center gap-6 w-full h-full  text-zinc-500 ">
      <p className="text-center">{translator('shortquote')}</p>
      <div className="flex flex-col items-center relative h-[45vw] w-[95vw] md:h-[12vw] md:w-[22vw] overflow-hidden ">
        <blockquote className="bg-white p-4 rounded-lg shadow  max-w-sm text-[7px] lg:text-[8px] absolute w-[90vw] md:w-[20vw] drop-shadow-2xl z-30">
          <div className="flex flex-row justify-between">
            <p className="text-zinc-800  font-extrabold line-clamp-1">
              {translator('quotebento1')}
            </p>
            <span>⭐⭐⭐⭐⭐</span>
          </div>

          <p className="text-zinc-500 my-4 line-clamp-2">
            {translator('quotebento2')}
          </p>

          <footer className="text-zinc-800 font-semibold flex flex-row justify-between items-center ">
            <span>Joanna S.</span>
            <span className="block text-zinc-500 text-sm font-normal">
              {translator('review4_job')}
            </span>
          </footer>
        </blockquote>
        <blockquote className="bg-white p-4 rounded-lg shadow  max-w-sm text-[7px] lg:text-[8px] top-10 absolute w-[80vw] md:w-[18vw] drop-shadow-2xl z-20 ">
          <div className="flex flex-row justify-between">
            <p className="text-zinc-800  font-extrabold">
              “The best interior design generator”
            </p>
            <span>⭐⭐⭐⭐⭐</span>
          </div>

          <p className="text-zinc-500 my-4 line-clamp-2 ">
            I've been in the interior design industry for over a decade, and I
            must say, incorporating this AI generator into my workflow has been
            an absolute game-changer.
          </p>

          <footer className="text-zinc-800 font-semibold flex flex-row justify-between items-center">
            <span>Kali D.</span>
            <span className="block text-zinc-500 text-sm font-normal">
              {translator('review3_job')}
            </span>
          </footer>
        </blockquote>
        <blockquote className="bg-white p-4 rounded-lg shadow  max-w-sm text-[7px] lg:text-[8px] top-20 absolute w-[75vw]  md:w-[16vw] drop-shadow-2xl">
          <div className="flex flex-row justify-between">
            <p className="text-zinc-800  font-extrabold">
              “The best interior design generator”
            </p>
            <span>⭐⭐⭐⭐⭐</span>
          </div>

          <p className="text-zinc-500 my-4 line-clamp-2">
            I've been in the interior design industry for over a decade, and I
            must say, incorporating this AI generator into my workflow has been
            an absolute game-changer.
          </p>

          <footer className="text-zinc-800 font-semibold flex flex-row justify-between items-center">
            <span>Emily Johnson</span>
            <span className="block text-zinc-500 text-sm font-normal">
              {translator('review1_job')}
            </span>
          </footer>
        </blockquote>
        {/* Other testimonials can go here */}
      </div>
      <div className="text-center text-zinc-500 text-base font-normal flex flex-col  gap-2 justify-center bg-transparent ">
        <span className="whitespace-nowrap">as featured in</span>
        <Image
          className="h-5"
          src="/nyt4.png"
          alt="New York Times"
          width={188}
          height={25}
        />
      </div>
    </div>
  );
}
